import { styled } from '@linaria/react'
import type { HTMLProps, ReactNode } from 'react'
import { useState } from 'react'

import { useBoundStore } from '../../store/store'
import Icon from '../icon'
import { TailSpin } from '../loaders'

const Container = styled.div`
  width: 100%;
`

const InputContainer = styled.div<{ variant?: 'secondary' }>`
  background-color: ${({ variant }) =>
    variant ? `var(--${variant})` : 'var(--primary)'};
  border-radius: 6px;
  display: flex;
  align-items: center;
  height: 36px;
  position: relative;
  padding: 0 12px;
  input {
    color: var(--txt);
    font-family: 'Poppins';
    font-size: 14px;
    flex: 1;
    margin-right: 12px;
    &::placeholder {
      color: var(--secondary-txt);
    }
  }
  &.referral {
    background-color: var(--primary);
  }
  .password-icon {
    fill: var(--secondary-txt);
  }
  @media (min-width: 1200px) {
    height: 38px;
    input {
      font-size: 16px;
    }
  }
`

const Suffix = styled.p`
  margin-right: 8px;
  color: var(--secondary-txt);
  font-weight: 400;
  font-size: 14px;
  font-family: 'Poppins';
  @media (min-width: 1200px) {
    font-size: 16px;
  }
`

const ErrorMessage = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
  color: rgb(255, 64, 88);
  font-family: 'Poppins';
`

export const Label = styled.label`
  text-transform: uppercase;
  color: var(--secondary-txt);
  font-size: 14px;
  font-family: 'Poppins';
  margin-bottom: 4px;
  display: block;
  @media (min-width: 1200px) {
    font-size: 16px;
  }
`

interface TextInputProps extends HTMLProps<HTMLInputElement> {
  suffix?: string
  isPassword?: boolean
  isValid?: boolean
  isReferral?: boolean
  isValidating?: boolean
  error?: string
  label?: string
  isDateInput?: boolean
  Badge?: ReactNode
  variant?: 'secondary'
}

const TextInput = ({
  suffix,
  isPassword,
  isValid,
  isReferral,
  isValidating,
  error,
  label,
  isDateInput,
  value,
  onChange,
  name,
  readOnly,
  Badge,
  variant,
  ...rest
}: TextInputProps) => {
  const theme = useBoundStore(state => state.common.theme)
  const [hide, setHide] = useState(true)

  return (
    <Container>
      {label && <Label>{label}</Label>}
      <InputContainer
        className={isReferral ? 'referral' : ''}
        style={{ marginBottom: error ? 9 : 14 }}
        variant={variant}>
        {suffix && <Suffix>{suffix}</Suffix>}
        <input
          type={isPassword ? (hide ? 'password' : 'text') : undefined}
          value={value}
          name={name}
          onChange={e => {
            if (!isDateInput) {
              return onChange?.(e)
            }

            const newValue = e.target.value
            const currentValue = (value ?? '') as string

            if (
              newValue.length > 10 ||
              (newValue && !/^\d+$/.test(newValue.replaceAll('/', '')))
            )
              return

            if (currentValue.length < newValue.length) {
              if (newValue.length === 2 || newValue.length === 5) {
                return onChange?.({
                  ...e,
                  target: {
                    ...e.target,
                    name,
                    value: newValue + '/',
                  } as EventTarget,
                })
              }
              if (newValue.length === 3 && newValue.charAt(2) !== '/') {
                return onChange?.({
                  ...e,
                  target: {
                    ...e.target,
                    name,
                    value: newValue.slice(0, 2) + '/' + newValue.slice(-1),
                  } as EventTarget,
                })
              }
              if (newValue.length === 6 && newValue.charAt(5) !== '/') {
                return onChange?.({
                  ...e,
                  target: {
                    ...e.target,
                    name,
                    value: newValue.slice(0, 5) + '/' + newValue.slice(-1),
                  } as EventTarget,
                })
              }
            }

            onChange?.(e)
          }}
          onKeyDown={e => {
            if (!isDateInput) return
            if (e.key === '/') {
              e.stopPropagation()
              e.preventDefault()
            }
          }}
          readOnly={readOnly}
          {...rest}
        />
        {isValidating ? (
          <TailSpin
            color={theme === 'dark' ? '#fff' : '#0E091E'}
            width={18}
            height={18}
          />
        ) : (
          isValid && <Icon id="check-circle" width="18" height="18" />
        )}
        {isPassword && (
          <button
            type="button"
            className="flx-ctr"
            onClick={() => setHide(prev => !prev)}>
            {hide ? (
              <Icon
                id="eye-slash-2"
                width="18"
                height="17"
                fill="#fff"
                className="password-icon"
              />
            ) : (
              <Icon
                id="eye-2"
                width="18"
                height="13"
                fill="white"
                className="password-icon"
              />
            )}
          </button>
        )}
        {Badge}
      </InputContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  )
}

export default TextInput
