import type { ReactNode } from 'react'

import type { AllGamesKey } from '../types/game'

export const INITIAL_ALL_GAMES = {
  '15665': [],
  '15652': [],
  '15656': [],
  '15650': [],
  '15647': [],
  '15653': [],
  '15649': [],
  '15657': [],
  "recent_played": [],
  "home": [],
  "favorites": [],
}

export interface CasinoCategory {
  label: string
  path: string
  value: AllGamesKey
  img: ReactNode
  hidden?: boolean
}

export const LOADING_HTML = `
  <meta
    name="viewport"
    content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no,viewport-fit=cover"
  />
  <style>
    .splash-screen-container {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 20;
      width: 100vw;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #fff;
    }

    .splash-screen-container iframe {
      border: none;
      outline: none;
      width: 200px;
      height: 200px;
    }

    @media (min-width: 1200px) {
      .splash-screen-container iframe {
        width: 250px;
        height: 250px;
      }
    }
  </style>
  <div id="splash-screen" class="splash-screen-container">
    <iframe src="https://fun88mx.mx/loading.html"></iframe>
    <noscript>Activate javascript.</noscript>
  </div>
`
