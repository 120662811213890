import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { css } from '@linaria/core'
import { styled } from '@linaria/react'
import { useNavigate } from '@tanstack/router'
import React from 'react'
import { useTranslation } from 'react-i18next'

import shirt_banner from '../../assets/common/shirt_banner.gif'
import closeModal from '../../utils/closeModal'
import Icon from '../icon'
import DefaultButton from './default-button'
import Modal from './modal'

const container = css`
  border: none;
  background-color: transparent;
  padding: 0;
  width: 300px;
  @media (min-width: 1200px) {
    width: 400px;
  }
`

const Banner = styled.img`
  width: 300px;
  aspect-ratio: 209 / 261;
  border-radius: 24px;
  @media (min-width: 1200px) {
    width: 400px;
  }
`

const button = css`
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  @media (min-width: 1200px) {
    bottom: -45px;
  }
`

const Close = styled.button`
  position: absolute;
  top: -35px;
  right: -5px;
  background-color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  svg {
    width: 12px;
    height: 12px;
    fill: #444;
  }
`

const ShirtBanner = () => {
  const navigate = useNavigate()
  const modal = useModal()
  const { t } = useTranslation('common')

  return (
    <Modal containerStyle={container} id="shirt-banner">
      <Banner src={shirt_banner} alt="Shirt Promo" />
      <Close className="flx-ctr" onClick={() => closeModal(modal)}>
        <Icon id="close" />
      </Close>
      <DefaultButton
        className={button}
        variant="blue"
        size="small"
        onClick={() => {
          navigate({ to: '/promotions/$promoId', params: { promoId: '346-promocion-newcastle' } })
          closeModal(modal)
        }}>
        {t('more-info')}
      </DefaultButton>
    </Modal>
  )
}

export default NiceModal.create(ShirtBanner)
