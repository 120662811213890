import { lazy,Route } from '@tanstack/router'

import { rootRoute } from './root'

const Register = lazy(() => import('../pages/register'))

export type RegisterSearch = {
  referral?: string
  utm_source?: string
  utm_medium?: string
  utm_campaign?: string
  uid?: string
  fbclid?: string
}

export const registerRoute = new Route({
  getParentRoute: () => rootRoute,
  path: 'register',
  component: Register,
  validateSearch: (search: Record<string, unknown>): RegisterSearch => {
    return search
  },
})
