import { styled } from '@linaria/react'

const StyledBackdrop = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 18;
  width: 100vw;
  height: 100vh;
  background-color: rgba(32, 38, 50, 0.7);
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
`

interface BackdropProps {
  onClick?: () => void
  isVisible: boolean
  id?: string
  zIndex?: number
}

const Backdrop = ({ onClick, isVisible, id, zIndex = 18 }: BackdropProps) => {
  return (
    <StyledBackdrop
      style={{ zIndex }}
      isVisible={isVisible}
      id={id ?? 'backdrop'}
      onClick={onClick}
    />
  )
}

export default Backdrop
