import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { styled } from '@linaria/react'
import { lazy, Suspense, useEffect, useState } from 'react'
import { Portal } from 'react-portal'

import useDimensions from '../../hooks/useDimensions'
import closeModal from '../../utils/closeModal'
import { Backdrop, Spinner } from '../common'
import Tabs from './tabs'

const Deposit = lazy(() => import('./deposit'))
const Withdraw = lazy(() => import('./withdraw'))

const Container = styled.div`
  width: 100%;
  height: 100vh;
  max-width: 1100px;
  padding: 0;
  border-radius: 0px;
  border-width: 0px;
  background-color: var(--primary);
  top: 0;
  left: 0;
  z-index: 204;
  position: fixed;
  @media (min-width: 1200px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: unset;
    border-radius: 12px;
    transition:
      opacity 0.3s ease-in-out,
      transform 0.3s ease-in-out;
    &.active {
      transform: translate(-50%, -50%);
    }
  }
`

type WalletManagementModalProps = {
  walletManagementDefaultTab?: {
    tab?: number
  }
}

const WalletManagementModal = ({
  walletManagementDefaultTab,
}: WalletManagementModalProps) => {
  const { isDesktop } = useDimensions()
  const modal = useModal()
  const [activeTab, setActiveTab] = useState(
    walletManagementDefaultTab?.tab ?? 0,
  )

  useEffect(() => {
    setActiveTab(walletManagementDefaultTab?.tab ?? 0)
  }, [walletManagementDefaultTab])

  return (
    <Portal>
      {isDesktop && (
        <Backdrop
          zIndex={203}
          isVisible={modal.visible}
          onClick={() => closeModal(modal)}
        />
      )}
      <Container id="wallet-management-modal">
        <Tabs
          activeTab={activeTab}
          setActiveTab={tab => {
            setActiveTab(tab)
          }}
          hide={() => closeModal(modal)}
        />
        <Suspense fallback={<Spinner containerStyle={{ padding: '36px 0' }} />}>
          {activeTab === 0 ? <Deposit /> : <Withdraw />}
        </Suspense>
      </Container>
    </Portal>
  )
}

export default NiceModal.create(WalletManagementModal)
