import './i18n'

import { init as initFullStory } from '@fullstory/browser'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { Suspense } from 'react'
import { hydrate, render } from 'react-dom'
import TagManager from 'react-gtm-module'

import App from './App'
import { Loading } from './components/common'
import ErrorBoundary from './hoc/ErrorBoundary'

TagManager.initialize({
  gtmId: 'GTM-WTVWFDL5',
})

initFullStory({ orgId: 'o-21BCM3-na1' })

dayjs.extend(customParseFormat)

const Root = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  </ErrorBoundary>
)

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const rootElement = document.getElementById('root')!
if (!rootElement.innerHTML) {
  if (rootElement?.hasChildNodes()) {
    hydrate(<Root />, rootElement)
  } else {
    render(<Root />, rootElement)
  }
}
