import { useModal } from '@ebay/nice-modal-react'
import type { LinariaClassName } from '@linaria/core'
import { css, cx } from '@linaria/core'
import { type PropsWithChildren, useEffect } from 'react'
import { Portal } from 'react-portal'

import closeModal from '../../utils/closeModal'
import Backdrop from './backdrop'

const defaultStyles = css`
  position: fixed;
  top: 50%;
  left: 50%;
  width: calc(100% - 60px);
  max-width: 400px;
  padding: 35px 20px 30px;
  border-radius: 20px;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  background-color: var(--primary);
  border: 2px solid var(--tertiary);
  transform: translate(-50%, calc(-50% - 50px));
  opacity: 0;
  pointer-events: none;
  &.active {
    opacity: 1;
    pointer-events: auto;
    transform: translate(-50%, -50%);
  }
`

type ModalProps = PropsWithChildren<{
  id: string
  containerStyle?: LinariaClassName
  zIndex?: number
  onModalClose?: () => void
  disableOverlayClose?: boolean
  hideOverlay?: boolean
}>

const Modal = ({
  children,
  containerStyle,
  id,
  zIndex,
  onModalClose,
  disableOverlayClose,
  hideOverlay,
}: ModalProps) => {
  const modal = useModal()

  const isVisible = modal.visible

  useEffect(() => {
    isVisible
      ? document.body.classList.add('noscroll')
      : document.body.classList.remove('noscroll')
  }, [isVisible])

  return (
    <Portal>
      {!hideOverlay && (
        <Backdrop
          id={id}
          isVisible={isVisible}
          onClick={() => {
            onModalClose?.()
            !disableOverlayClose && closeModal(modal)
          }}
          zIndex={zIndex ?? 203}
        />
      )}
      <div
        className={cx(defaultStyles, containerStyle, isVisible && 'active')}
        style={{
          zIndex: zIndex ? zIndex + 1 : 204,
        }}>
        {children}
      </div>
    </Portal>
  )
}

export default Modal
