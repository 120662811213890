import { useModal } from '@ebay/nice-modal-react'
import { styled } from '@linaria/react'
import { Link } from '@tanstack/router'
import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import best_odds from '../../assets/common/best_odds.webp'
import blueArrow from '../../assets/common/blue_arrow.webp'
import casino_after from '../../assets/common/casino_after.webp'
import casino_before from '../../assets/common/casino_before.webp'
import deportes_after from '../../assets/common/deportes_after.webp'
import deportes_before from '../../assets/common/deportes_before.webp'
import favoritos_after from '../../assets/common/favoritos_after.webp'
import favoritos_before from '../../assets/common/favoritos_before.webp'
import more_games from '../../assets/common/more_games.webp'
import more_live from '../../assets/common/more_live.webp'
import recently_after from '../../assets/common/recently_after.webp'
import recently_before from '../../assets/common/recently_before.webp'
import referral_after from '../../assets/common/referral_after.webp'
import referral_before from '../../assets/common/referral_before.webp'
import useGames from '../../hooks/useGames'
import useGeoCheck from '../../hooks/useGeoCheck'
import { useBoundStore } from '../../store/store'
import LoginModal from '../common/log-in-modal'

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 120;
  background-color: var(--primary);
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 0 12px;
  height: 60px;
  align-items: center;
  width: 100%;
  transition: all 0.2s ease-in-out;
`

const Tab = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    position: relative;
  }
  svg {
    width: 25px;
    height: 25px;
    fill: #888;
    path {
      fill: #888;
    }
  }
  img {
    width: 25px;
    // height: 25px;
    transition: all 0.2s ease-in-out;
  }
  .icon.arrow {
    img {
      aspect-ratio: 697 / 611;
    }
  }
  &.active {
    p {
      color: var(--highlight);
    }
    svg {
      fill: var(--highlight);
      path {
        fill: var(--highlight);
      }
    }
  }
  p {
    font-size: 10px;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    color: #888;
    text-align: center;
  }
  &.toggle {
    position: absolute;
    right: 12px;
    bottom: 8px;
    width: calc(100% / 4 - 24px);
    transition: all 0.2s ease-in-out;
    &.hidden {
      bottom: 68px;
    }
  }
`

const BottomNavIcon = styled(LazyLoadImage)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: var(--translate-50-50) rotate(0deg);
  width: 80%;
  height: unset;
  aspect-ratio: 1 / 1;
`

const IconAfter = styled(BottomNavIcon)`
  opacity: 0;
`

const IconBefore = styled(BottomNavIcon)`
  opacity: 1;
`

const IconBadge = styled.img`
  position: absolute;
  width: 25px !important;
  top: -7px;
  right: 5px;
  z-index: 5;
`

const duration = 1
const Gif = styled.div`
  --translate-50-50: translate(-50%, -50%);
  position: relative;
  overflow: hidden;
  width: 30px;
  height: 30px;
  margin: -5px;

  @keyframes spin {
    10% {
      width: 50%;
      transform: var(--translate-50-50) rotate(0deg);
    }

    50% {
      width: 100%;
    }
    100% {
      width: 80%;
      transform: var(--translate-50-50) rotate(720deg);
    }
  }

  @keyframes after {
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes before {
    50% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  & ${IconAfter}, & ${IconBefore} {
    animation-name: none;
  }

  ${Tab}.active & ${IconAfter} {
    animation-name: spin, after;
    animation-duration: ${duration}s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: none, forwards;
  }

  ${Tab}.active & ${IconBefore} {
    animation-name: spin, before;
    animation-duration: ${duration}s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: none, forwards;
  }
`

type TabObj = {
  icon: ReactNode
  badge?: ReactNode
  label: string
  link: string
  onLinkClick: undefined | (() => void)
  isProtected: boolean
}

const BottomNav = () => {
  const username = useBoundStore(state => state.user.username)
  const isFullScreen = useBoundStore(state => state.common.isFullScreen)
  const setIsFullScreen = useBoundStore(state => state.common.setIsFullScreen)
  const { geoCheck } = useGeoCheck()
  const { loadMoreGames } = useGames()
  const logInModal = useModal(LoginModal)
  const {
    t,
    i18n: { language },
  } = useTranslation(['drawer', 'casino'])
  const fullPath = location.pathname + location.search

  const TABS: TabObj[] = [
    ...(fullPath.includes('/casino')
      ? [
          {
            icon: (
              <Gif>
                <IconAfter alt="Deportes" src={deportes_after} />
                <IconBefore alt="Deportes" src={deportes_before} />
              </Gif>
            ),
            label: t('sports'),
            link: language === 'zh' ? '/sports/im' : '/sports/bti',
            onLinkClick: undefined,
            isProtected: false,
          },
        ]
      : [
          {
            icon: (
              <Gif>
                <IconAfter alt="Referral" src={referral_after} />
                <IconBefore alt="Referral" src={referral_before} />
              </Gif>
            ),
            label: t('invite'),
            link: '/profile/referral/',
            onLinkClick: undefined,
            isProtected: true,
          },
        ]),
    {
      icon: (
        <Gif>
          <IconAfter alt="Casino Favoritos" src={favoritos_after} />
          <IconBefore alt="Casino Favoritos" src={favoritos_before} />
        </Gif>
      ),
      label: t('casino:favorites'),
      link: '/casino/favoritos',
      onLinkClick: undefined,
      isProtected: true,
    },
    ...(fullPath.includes('/casino')
      ? [
          {
            icon: (
              <Gif>
                <IconAfter alt="Casino Jugar" src={recently_after} />
                <IconBefore alt="Casino Jugar" src={recently_before} />
              </Gif>
            ),
            label: t('recent'),
            link: '/casino/jugar',
            onLinkClick: () => {
              loadMoreGames('recent_played', '', true)
            },
            isProtected: true,
          } as TabObj,
        ]
      : [
          {
            icon: (
              <Gif>
                <IconAfter alt="Deportes" src={deportes_after} />
                <IconBefore alt="Deportes" src={deportes_before} />
              </Gif>
            ),
            label: t('sports'),
            link: language === 'zh' ? '/sports/im' : '/sports/bti',
            onLinkClick: undefined,
            isProtected: false,
          },
        ]),
    {
      icon: (
        <Gif>
          <IconAfter alt="Casino" src={casino_after} />
          <IconBefore alt="Casino" src={casino_before} />
        </Gif>
      ),
      label: t('casino'),
      link: `/casino/inicio`,
      onLinkClick: () => {
        loadMoreGames('15665', '', true)
      },
      isProtected: false,
    },
  ]

  const SPORT_TABS: TabObj[] = [
    {
      icon: (
        <Gif>
          <IconAfter alt="Casino" src={casino_after} />
          <IconBefore alt="Casino" src={casino_before} />
        </Gif>
      ),
      label: t('casino'),
      link: `/casino/inicio`,
      onLinkClick: undefined,
      isProtected: false,
    },
    {
      icon: (
        <Gif>
          <IconAfter alt="Deportes" src={deportes_after} />
          <IconBefore alt="Deportes" src={deportes_before} />
        </Gif>
      ),
      badge: (
        <IconBadge
          alt="Sports"
          src={language === 'zh' ? more_live : best_odds}
        />
      ),
      label: `${t('sports')} 1`,
      link: language === 'zh' ? '/sports/im' : '/sports/bti',
      onLinkClick: undefined,
      isProtected: false,
    },
    {
      icon: (
        <Gif>
          <IconAfter alt="Deportes" src={deportes_after} />
          <IconBefore alt="Deportes" src={deportes_before} />
        </Gif>
      ),
      badge: (
        <IconBadge
          alt="Sports"
          src={language === 'zh' ? best_odds : more_games}
        />
      ),
      label: `${t('sports')} 2`,
      link: language === 'zh' ? '/sports/bti' : '/sports',
      onLinkClick: undefined,
      isProtected: false,
    },
    {
      icon: (
        <Gif>
          <IconAfter alt="Deportes" src={deportes_after} />
          <IconBefore alt="Deportes" src={deportes_before} />
        </Gif>
      ),
      badge: (
        <IconBadge
          alt="Sports"
          src={language === 'zh' ? more_games : more_live}
        />
      ),
      label: `${t('sports')} 3`,
      link: language === 'zh' ? '/sports' : '/sports/im',
      onLinkClick: undefined,
      isProtected: false,
    },
  ]

  return (
    <Container style={{ bottom: isFullScreen ? -60 : 0 }}>
      {(fullPath.includes('/sports') ? SPORT_TABS : TABS).map(tab => (
        <Link
          to={tab.isProtected && !username ? undefined : tab.link}
          key={tab.label}
          onClick={tab.onLinkClick}
          search={{}}
          params={{}}>
          <Tab
            className={
              tab.link.includes(`/casino/inicio`)
                ? fullPath.includes('/casino') &&
                  !fullPath.includes('favoritos') &&
                  !fullPath.includes('jugar')
                  ? 'active'
                  : ''
                : fullPath === tab.link
                ? 'active'
                : fullPath === tab.link
                ? 'active'
                : ''
            }
            onClick={() => {
              if (tab.isProtected && !username) {
                geoCheck(logInModal.show)
              }
            }}>
            {tab.badge}
            <div className="icon">{tab.icon}</div>
            <p>{tab.label}</p>
          </Tab>
        </Link>
      ))}
      <div />
      <Tab
        className={`toggle ${isFullScreen ? 'hidden' : ''}`}
        onClick={() => setIsFullScreen(!isFullScreen)}>
        <div className="icon arrow">
          <img
            src={blueArrow}
            alt="hide"
            style={{ rotate: `${isFullScreen ? 0 : 180}deg` }}
          />
        </div>
        {!isFullScreen && <p>{t('expand')}</p>}
      </Tab>
    </Container>
  )
}

export default BottomNav
